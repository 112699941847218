<template>
  <div class="hk_page_routerview">
    <div class="hk_page_title hk_page_title_tabs">
      <div class="hk_page_title_left">
        <div class="title_txt">退费审核--开发中</div>
        <el-tabs class="title_tabs" v-model="activeName">
          <el-tab-pane label="未审核" name="ON_SELL"></el-tab-pane>
          <el-tab-pane label="已审核" name="OFF_SELL"></el-tab-pane>
        </el-tabs>
      </div>
      <!-- <div class="hk_page_title_right">
        <el-button type="primary" size="small" plain @click="ifShowAdd=true">退费申请</el-button>
      </div> -->

    </div>

    <div class="hk_page_wrap hk_page_wrap_tabs">
      <div class="hk-common-page">
        <div class="hk_search_box">
          <div class="hk_search_box_item">
            搜索：<el-input debounce size="small" clearable placeholder="订单号" v-model="searchOrder">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="hk_search_box_item">
            <el-input debounce size="small" clearable placeholder="上课手机号" class="hk-ipt-number" v-model.number="searchPhone">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>

          </div>
          <div class="hk_search_box_item">
            退费原因：
            <el-select v-model="searchType" size="small" clearable placeholder="请选择">
              <el-option label="全部" value="">全部</el-option>
              <el-option label="小组课" value="LITTLE_GROUP">小组课</el-option>
              <el-option label="刷题课" value="EXERCISE">刷题课</el-option>
            </el-select>
          </div>
          <div class="hk_search_box_item">
            登记时间：
            <el-date-picker v-model="searchTime" size="small" class="ipt-date-suffix" type="daterange" clearable range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>

          </div>
          <div class="hk_search_box_item">
            <el-button type="primary" size="small" @click="searchFun">搜索</el-button>
            <el-button size="small">重置</el-button>
          </div>
        </div>
        <div class="hk_result_total_box">
          共有 <span class="hk-font-highlight">{{totalNum}}</span> 条信息
        </div>

        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="填写信息" width="110px">
            <template slot-scope="scope">
              <img class="hk-product-tb-img" :src="scope.row.productImg" alt="">
            </template>
          </el-table-column>
          <el-table-column label="订单号" width="180px">
            <template slot-scope="scope">
              <span>{{scope.row.productName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="上课手机号" width="120px">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column label="课程名称" width="180px">
            <template slot-scope="scope">
              {{ (scope.row.id) }}
            </template>
          </el-table-column>
          <el-table-column label="责任辅导" width="120px">
            <template slot-scope="scope">
              {{ scope.row.sellPrice/100 }}
            </template>
          </el-table-column>
          <el-table-column label="买家手机号" width="120px">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column label="登记时间" width="120px">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column label="退换情况" width="120px">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column label="退费原因" width="120px">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column label="退费详情" width="180px">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column label="退费讲次" width="120px">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <template v-if="activeName!=='ON_SELL'">
            <el-table-column label="退费时间" width="120px">
              <template slot-scope="scope">
                {{ scope.row.id }}
              </template>
            </el-table-column>
            <el-table-column label="退费金额" width="120px">
              <template slot-scope="scope">
                {{ scope.row.id }}
              </template>
            </el-table-column>
            <el-table-column width="140px">
              <template slot="header">
                扣款说明
                <el-popover placement="top" width="280" trigger="hover">
                  <div>①邮寄教材后至第2次课前：扣100讲义费</div>
                  <div>②第2次课后：扣已更新课次+100讲义费</div>
                  <span class="hk-font-highlight el-icon-warning" slot="reference"></span>
                </el-popover>
              </template>
              <template slot-scope="scope">
                {{ scope.row.id }}
              </template>
            </el-table-column>
            <el-table-column label="classIn是否移除" width="130px">
              <template slot-scope="scope">
                {{ scope.row.id }}
              </template>
            </el-table-column>
            <el-table-column label="班级群是否移除" width="120px">
              <template slot-scope="scope">
                {{ scope.row.id }}
              </template>
            </el-table-column>
          </template>
          <el-table-column label="备注" width="120px">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <template v-if="activeName!=='ON_SELL'">
            <el-table-column label="审批状态" width="120px">
              <template slot-scope="scope">
                {{ scope.row.id }}
              </template>
            </el-table-column>
            <el-table-column label="审批信息" width="120px">
              <template slot-scope="scope">
                {{ scope.row.id }}
              </template>
            </el-table-column>
          </template>

          <el-table-column label="操作" width="180px" fixed="right">
            <template slot-scope="scope">
              <template v-if="activeName=='ON_SELL'">
                <span class="hk-table-option hk-table-option-first" @click="showLinkDialogFun(scope.row)">审批</span>

              </template>
              <template v-else>
                <span class="hk-table-option hk-table-option-first" @click="showEditDailog(scope.row)">修改</span>
              </template>
            </template>
          </el-table-column>
          <div slot="empty">
            <p>暂无信息！</p>
          </div>
        </el-table>
        <div class="hk_pagination" v-if="totalNum>0">
          <el-pagination @current-change="handleCurPageChange" @size-change="handleSizeChange" :current-page.sync="curPage" :page-size="pageSize" :page-sizes="[10,20, 50, 100]" layout="total,sizes,prev, pager, next" :total="totalNum">
          </el-pagination>
        </div>
      </div>
    </div>

    <!--退费审批窗口-->
    <el-dialog width="496px" title="退费审批" :visible.sync="refundApplyFlag" :close-on-click-modal="false" @close="hideRefundApply" append-to-body class="refund-dialog">
      <el-alert title="退款后，系统将自动吧学员移出classin班级。" type="error" :closable="false" show-icon>
      </el-alert>
      <div class="refund-block">
        <div class="refund-label">学员信息</div>
        <div class="refund-item">
          <span class="item-title">上课手机号：</span>xxxx &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span class="item-title">买家手机号：</span>xxxx
        </div>
        <div class="refund-label">订单信息</div>
        <div class="refund-item">
          <span class="item-title">订单号：</span>
          EDEMO1111122222
        </div>
        <div class="refund-item">
          <span class="item-title">课程名称：</span>
          EDEMO1111122222
        </div>
        <div class="refund-item">
          <span class="item-title">已上课节：</span>
          EDEMO1111122222
        </div>
        <div class="refund-item">
          <span class="item-title">退费讲次：</span>
          EDEMO1111122222
        </div>
        <div class="refund-item">
          <span class="item-title">责任辅导：</span>
          EDEMO1111122222
        </div>
        <div class="refund-item">
          <span class="item-title">登记时间：</span>
          EDEMO1111122222
        </div>
        <div class="refund-item">
          <span class="item-title">退费原因：</span>
          EDEMO1111122222
        </div>
        <div class="refund-item">
          <span class="item-title">退费详情：</span>
          EDEMO1111122222
        </div>
        <div class="refund-item">
          <span class="item-title">备注：</span>
          EDEMO1111122222
        </div>
        <div class="refund-label">退费信息</div>
        <el-form ref="refundApplyForm" :rules="refundApplyRule" :model="refundApplyForm" @submit.native.prevent size="small" label-width="92px">
          <el-form-item label="退费金额：" prop="money">
            <el-input type="text" v-model.number="refundApplyForm.money" class="hk-ipt-number" style="width:220px;"></el-input>&nbsp;￥
          </el-form-item>

          <el-form-item label="登记时间：" prop="refundTime">
            <el-date-picker v-model="refundApplyForm.refundTime" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="扣款说明：" prop="reason">
            <el-input type="textarea" placeholder="①邮寄教材后至第2次课前：扣100讲义费
②第2次课后：扣已更新课次+100讲义费" :autosize="{ minRows: 2, maxRows: 8}" v-model="refundApplyForm.reason"></el-input>
          </el-form-item>

        </el-form>

        <div class="refund-footer" style="">
          <!-- <el-button size="small" @click.native="hideRefundApply">取消</el-button> -->

          <el-button plain size="small" style="margin-right:10px;" @click.native="refuseDialog=true">拒绝</el-button>

          <el-popover placement="top" width="200" trigger="click" v-model="refundPopVisible">
            <p style="padding: 4px 8px;font-size: 14px;color:#333;line-height: 22px;"><span class="c-f-primary iconfont icon-gantan"></span> 请检查填写的数据，确定修改吗？</p>
            <div style="text-align: right; margin: 4px 0 0;">
              <el-button size="mini" type="text" @click="refundPopVisible = false">取消</el-button>
              <el-button type="primary" size="mini" @click.native="submitRefundListApply">确定</el-button>
            </div>
            <el-button type="primary" size="small" slot="reference">通过</el-button>

            <!--<span class="orange-color" slot="reference">{{scope.row.productName}}</span>-->
          </el-popover>

        </div>
      </div>
    </el-dialog>

    <!--拒绝理由弹窗-->
    <el-dialog width="446px" title="拒绝理由" :visible.sync="refuseDialog" :close-on-click-modal="false" @close="hideRefuseFun" append-to-body class="refund-dialog">
      <div class="refund-block">
        <el-input type="textarea" placeholder="请输入拒绝理由" :autosize="{ minRows: 4, maxRows: 8}" v-model="refuseReason"></el-input>

        <div style="text-align: right;margin: 20px 10px 0 0;">
          <el-button size="small" @click.native="hideRefuseFun">取消</el-button>
          <el-button size="small" type="primary" @click.native="submitRefuseFun">提交</el-button>
        </div>
      </div>

    </el-dialog>

    <!-- “填写人信息”的查看 -->
    <el-dialog title="填写信息" :visible.sync="ifFillInDialog" :modal-append-to-body="false" width="446px" @close="closeDetailDialog">
      <div class="fillin-dialog">
        <div class="fi-item">xxx xxxx作废</div>
        <div class="fi-item">xxx xxxx驳回</div>
        <div class="fi-item">xxx xxxx修改</div>
        <div class="fi-item">xxx xxxx录入</div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// import { GetProductListApi } from '../../request/api';
import QRCode from 'qrcode'
import { validatePositiveInteger, validateDiscount } from '../../utils/validate';
export default {
  data() {
    return {
      activeName: 'ON_SELL', //状态

      searchOrder: "", //订单号
      searchPhone: "", //上课手机号
      searchType: "EXERCISE", //退费原因
      searchTime: [], //登记时间
      tableData: [], //列表中显示的数据(搜索后,只显示符合条件)
      pageSize: 10, //每页10条数据
      curPage: 1, //当前第几页
      totalNum: 0, //总共几条数据

      refundApplyFlag: true, //审批弹窗
      stuRefundInfo: {}, //学生信息
      refundPopVisible: false, //确认退款的popover是否显示

      refuseDialog: false, //退费驳回的弹窗
      refuseReason: '', //驳回原因
      ifSeeApply: true, //true--查看审批

      refundApplyForm: {
        money: '', //退费金额
        refundTime: '', //登记时间
        reason: '', //原因
      },
      refundApplyRule: {
        money: [
          { required: true, message: '请输入', trigger: 'blur' }
        ], //退费金额
        refundTime: [
          { required: true, message: '请选择', trigger: 'blur' }
        ], //登记时间
        reason: [
          { required: true, message: '请输入', trigger: 'blur' }
        ], //原因
      },
      //正整数验证 { required: true, validator: validatePositiveInteger, trigger: 'blur', }, //自定义验证

      ifFillInDialog: true, //查看填写信息弹窗
    }
  },
  watch: {

    // 'addForm.discount': {
    //   handler(val, old) {
    //     if (this.addForm.productPrice) {
    //       //计算折扣
    //       this.countDiscountFun()
    //     }

    //   },
    //   immediate: true,
    //   deep: true
    // },
    activeName(val, old) { //上下架状态
      // this.searchFun() //重新从第一页开始
    }
  },

  mounted() {
    let that = this;
    // that.getProductFun()
    // that.getClassCourseList(); //获取班级列表，用于弹窗
  },
  methods: {
    //关闭退费申请弹窗
    hideRefundApply() {
      this.refundPopVisible = false;
      this.refundApplyFlag = false;
    },

    //关闭驳回原因
    hideRefuseFun() {
      this.refuseDialog = false;
    },

    //确认 驳回退费
    submitRefuseFun() {
      let that = this;
      console.log('确认驳回 fun')
      // if (that.refuseReason.trim().length) {

      //   that.$confirm('确定驳回退费?', '', {
      //     confirmButtonText: '驳回',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     let param = {
      //       remark: that.refuseReason,
      //       id: that.stuRefundInfo.id
      //     }
      //     that.request({
      //       method: 'post',
      //       url: '/manage/classhourpackage/approval_refund_v3.do',
      //       param: param,
      //       success: function (result) {
      //         if (result.code == 200) {
      //           that.hideRefuseFun();
      //           that.hideRefundApply();
      //           that.$emit('uploadNoticeList');
      //         } else {
      //           that.$message.error('驳回退费失败,' + result.message);
      //         }
      //       },
      //       error: function (error) {
      //         //服务器异常
      //         console.log(error)
      //         that.$message.error('驳回退费失败了。')
      //       }
      //     });

      //   }).catch(() => {
      //     console.log("取消驳回")
      //   });
      // } else {
      //   that.$message.error('请输入驳回原因。')
      // }


    },

    //分页
    handleCurPageChange(val) {
      console.log(`当前页: ${val}`);
      this.curPage = val
      this.getProductFun();
    },
    //修改每页展示的记录个数
    handleSizeChange(val) {
      console.log(`每页显示: ${val} 条数据`);
      this.pageSize = val
      this.curPage = 1
      this.getProductFun();
    },

    //搜索
    searchFun() {
      this.curPage = 1
      this.tableData = []
      this.totalNum = 0
      this.getProductFun()
    },

    //获取班课商品列表
    getProductFun() {
      let that = this;
      let param = {
        pageNum: that.curPage,
        pageSize: that.pageSize,
        productStatus: that.activeName //商品状态，上下架

      };
      if (that.searchOrder && that.searchOrder.trim().length > 0) {
        param.productName = that.searchOrder.trim()
      }
      if (that.searchType) {
        param.productType = 'DOU_DIAN'
      }
      that.request({
        method: 'post',
        url: '/manage/product/listDouDianProduct',
        param: param,
        success: function (result) {
          if (result.code == 200) {

            let data = result.result;
            that.tableData = data.list;
            that.totalNum = Number(data.total);
          } else {
            console.log(result, '----not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取失败。')
          console.log(error, '-----获取失败')
        }
      })


      // GetProductListApi().then(res=>{
      //     debugger
      //     console.log(res,'-----获取商品列表')
      // })    
    },

    //获取班级列表
    getClassCourseList() {
      let that = this;
      let params = {}
      that.request({
        method: 'post',
        url: '/manage/1v1/listCourseForProduct',
        param: params,
        success: function (res) {

          if (res.code == 200) {
            that.classList = res.result || []

          } else {
            that.$message.error('获取失败--' + res.msg)
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error('获取列表异常');
        }
      })

    },



    //获取上传的文件名称
    getFileName(file) {
      var pos = file.lastIndexOf("\\");
      return file.substring(pos + 1);
    },
    //上传图片
    changeUpload(eleId, imgType) {
      let that = this;
      that.ifShowUploading = true;

      let params = {
        ossPath: 'PRODUCT',
        fileName: that.getFileName(document.getElementById(eleId).value)
      }
      let file = document.getElementById(eleId).files[0];

      that.request({
        method: 'post',
        url: '/tool/getOauthUrl',
        param: params,
        success: function (res) {

          var fileUrl = res.result; //返回的图片地址，和xhr.responseURL一样
          // 2. 上传文件
          var xhr = new XMLHttpRequest();
          xhr.open("PUT", fileUrl, true);
          // 注意不要传入Content-Type，否则会出现签名错误
          xhr.setRequestHeader('Content-Type', '');
          xhr.onload = function () {
            // 请求结束后，在此处编写处理代码。
            if (xhr.readyState == 4) {
              let backResponse = xhr.responseURL; //直接返回的图片url,图片处理域名并截取？之前地址
              // https://kelivemanage.oss-cn-beijing.aliyuncs.com/pre/product/2024/20240919115613101.jpg?Expires=1726718474&OSSAccessKeyId=LTAI5tBc2wB3JcdbJhrXgpXC&Signature=Us%2FGfiJ19QshtXQHUuqelpsK1P8%3D

              let endIdx = backResponse.indexOf('?')
              let imgUrl = backResponse.substring(0, endIdx)
              let newUrl = imgUrl.replace('//kelivemanage.oss-cn-beijing.aliyuncs.com', '//oss.ke.live')
              console.log(newUrl)

              if (imgType == 'desc') {
                //描述
                that.$set(that.addForm, 'productDetailImg', newUrl)
                // that.addForm.productDetailImg = newUrl;
              } else {
                //头图
                that.$set(that.addForm, 'productImg', newUrl)
                // that.addForm.productImg = newUrl;
              }
              that.ifShowUploading = false;
              document.getElementById(eleId).value = '';
            }
          };
          xhr.send(file);

        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error('上传失败');
        }
      })

    },

    //切换班级,只有新建的时候，才会触发
    classChangeFun(item) {
      let that = this;
      // that.selectClassObj = Object.assign({},item)
      that.selectClassObj = Object.assign({}, {
        id: item.id,
        courseName: item.courseName,
        courseType: item.courseType
      })
      that.$set(that.addForm, 'classId', item.id) //这里使用id,不是courseId
    },
    //显示编辑的弹窗
    showEditDailog(row) {

      // this.editId = row.id
      // this.$set(this.addForm, 'productName', row.productName)
      // this.$set(this.addForm, 'productType', row.productType)
      // this.$set(this.addForm, 'classId', row.refId)
      // this.$set(this.addForm, 'productPrice', row.originalPrice / 100)
      // this.$set(this.addForm, 'discount', row.sellPrice * 10 / row.originalPrice)
      // this.$set(this.addForm, 'productImg', row.productImg)
      // this.$set(this.addForm, 'productDetailImg', row.productDetailImg)
      // this.selectClassObj = Object.assign({}, row.keHkCourseDO)

      this.ifShowAdd = true
      // this.countDiscountFun()
    },

    //点击“查看详情”
    seeDetailFun(row) {
      this.detailRow = Object.assign({}, row)
      this.ifShowDetail = true
    },

    closeDetailDialog() {
      this.ifShowDetail = false

      this.detailRow = Object.assign({}, {})
    },



  }
}
</script>

<style lang="scss" scoped>
// 退费审批弹窗
.refund-dialog {
  ::v-deep .el-dialog__body {
    padding: 0 !important;
  }

  .refund-block {
    padding: 10px 20px 30px;
    max-height: 450px;
    overflow-y: auto;

    .refund-label {
      font-weight: 400;
      font-size: 14px;
      color: #bdbdbd;
      line-height: 22px;
      padding: 8px 0 0;
    }
    .refund-item {
      font-size: 14px;
      color: #111;
      line-height: 22px;
      margin: 6px 0;
      .item-title {
        color: #999;
      }
    }

    .refund-footer {
      text-align: right;
      margin: 0;
    }
  }
}
// 查看填写信息弹窗
.fillin-dialog {
  font-weight: 400;
  font-size: 14px;
  color: rgba(102, 102, 102, 0.85);
  line-height: 16px;
  .fi-item {
    padding-bottom: 12px;
    &:last-child {
      padding-bottom: 0;
    }
  }
}
</style>